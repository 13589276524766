// eslint-disable-next-line object-curly-newline
import {
  mdiAccountSupervisor,
  mdiAccountTie,
  mdiBullhorn,
  mdiCog,
  mdiCollage,
  mdiContactlessPayment,
  mdiCreditCard,
  mdiCrosshairs,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiFileLock,
  mdiFlash,
  mdiHomeOutline,
  mdiMapMarker,
  mdiOrderNumericAscending,
  mdiPointOfSale,
  mdiShieldAccount,
  mdiTrayFull,
  mdiViewList,
  mdiCalculatorVariant,
  mdiCalendarClock,
  mdiPlaylistCheck,
  mdiCalendarCheck,
  mdiShape,
  mdiFinance,
  mdiFileDocumentEdit
} from '@mdi/js'

// var standartx = 'KPanel'
// console.log(standartx)

// watch(
//   () => store.state.plasiyerCustomer,
//   () => {
//     if (store.state.secondState == true) {
//       standartx = 'GeneralSettings'
//       console.log(standartx)
//     }
//   },
// )

const accessToken = localStorage.getItem('accessToken')
export default [
  {
    title: 'Denetim Masası',
    icon: mdiHomeOutline,
    to: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    subheader: 'Hesaplar',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Yöneticiler',
    icon: mdiAccountTie,
    to: 'admin-list',
    resource: 'Users',
    action: 'read',  
    
  },
  {
    title: 'Kullanıcılar',
    icon: mdiAccountSupervisor,
    to: 'user-list',
    resource: 'Users',
    action: 'read',
  },
  {
    subheader: 'Sipariş',
    resource: 'QuickOrder',
    action: 'read',
  },
  {
    subheader: 'Ürün & Takip',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Ürünler',
    icon: mdiCollage,
    to: 'product-list',
    resource: 'ProductsMan',
    action: 'read',
  },

  {
    title: 'Sipariş Ver',
    icon: mdiFlash,
    to: 'order-easy',
    resource: 'Product',
    action: 'read',
  },

  {
    title: 'Sipariş Takip',
    icon: mdiOrderNumericAscending,
    to: 'order-tracking',
    resource: 'Product',
    action: 'read',
  },
  {
    subheader: 'Ödeme',
    resource: 'KPanel',
    action: 'read',
  },
  {
    title: 'Manuel Ödeme',
    icon: mdiContactlessPayment,
    to: 'payment',
    resource: 'PaymentResult',
    action: 'read',
  },

  {
    title: 'Kart Hareketleri',
    icon: mdiCreditCard,
    to: 'credit-card-transactions',
    resource: 'Payment',
    action: 'read',
  },
  {
    title: 'Pos Tanımları',
    icon: mdiPointOfSale,
    to: 'pos-tanimlari',
    resource: 'Users',
    action: 'read',
  },
  {
    subheader: 'Hesap',
    resource: 'KPanel',
    action: 'read',
  },

  {
    title: 'Kullanıcı Bilgilerim',
    icon: mdiShieldAccount,
    to: { name: 'user-view' },
    resource: 'KPanel',
    action: 'read',
  },
  /* {
    title: 'Adreslerim',
    icon: mdiMapMarker,
    //to: null,
    //to: { name: 'user-addresses' },
    to: 'error-405',
    resource: 'KPanel',
    action: 'read',
  }, */

  {
    title: 'Sözleşmelerim',
    icon: mdiFileLock,
    to: 'user-contracts',
    resource: 'KPanel',
    action: 'read',
  },
  {
    subheader: 'Masraflar',
    resource: 'ExpenseAdd',
    action: 'read',
  },
  {
    title: 'Yeni Masraf',
    icon: mdiCalculatorVariant,
    to: 'expense-add',
    resource: 'ExpenseAdd',
    action: 'read',
  },
  {
    title: 'Masraf Listesi',
    icon: mdiViewList,
    to: 'expense-list',
    resource: 'ExpenseList',
    action: 'read',
  },
  {
    subheader: 'Tahsilat',
    resource: 'ExpenseAdd',
    action: 'read',
  },  
  {
    title: 'Tahsilat Hesapla',
    icon: mdiCalculatorVariant,
    to: 'calculate-collection',
    resource: 'ExpenseList',
    action: 'read',
  },
  {
    subheader: 'İzinlerim',
    resource: 'ExpenseAdd',
    action: 'read',
  },
  {
    title: 'İzin Ekle',
    icon: mdiCalendarClock,
    //to: 'add-leave',
    to: 'error-405',
    resource: 'ExpenseAdd',
    action: 'read',
  },
  {
    title: 'İzin Taleplerim',
    icon: mdiCalendarCheck,
    to: 'error-405',
    //to: 'leave-list',
    resource: 'ExpenseList',
    action: 'read',
  },

  {
    subheader: 'Sistem',
    resource: 'Users',
    action: 'read',
  },
  {
    title: 'Kategoriler',
    icon: mdiShape,
    to: 'product-category',
    resource: 'ProductsMan',
    action: 'read',
  },

  {
    title: 'Duyurular',
    icon: mdiBullhorn,
    to: 'event-list',
    resource: 'EventsMan',
    action: 'read',
  },

  {
    subheader: 'Raporlama',
    resource: 'QuickOrder',
    action: 'read',
  },

  {
    title: 'Satış Belgeleri',
    icon: mdiFileDocument,
    resource: 'QuickOrder',
    action: 'read',
    children: [
      {
        title: 'Sipariş',
        to: { name: 'documents-list', params: { folder: 'order' } },
        resource: 'QuickOrder',
        action: 'read',
      },
      {
        title: 'İrsaliye',
        to: { name: 'documents-list', params: { folder: 'waybill' } },
        resource: 'QuickOrder',
        action: 'read',
      },
      {
        title: 'Fatura',
        to: { name: 'documents-list', params: { folder: 'bill' } },
        resource: 'QuickOrder',
        action: 'read',
      },
    ],
  },
  {
    title: 'Yaşlandırma Raporu',
    icon: mdiFinance,
    to: 'aging-report',
    resource: 'QuickOrder',
    action: 'read',
  },
  {
    title: 'Satış Sözleşmeleri',
    icon: mdiFileDocumentEdit,
    to: 'sales-contract-list',
    resource: 'QuickOrder',
    action: 'read',
  },
  {
    title: 'Ekstre',
    icon: mdiTrayFull,
    to: 'extract-list',
    resource: 'QuickOrder',
    action: 'read',
  },
  /*
  {
    title: 'Toplu SMS',
    icon: mdiMessageProcessing,
    to: 'sms-list',
    resource: 'Users',
    action: 'read',
  },
  */
  {
    title: 'Loglar',
    icon: mdiCrosshairs,
    to: 'logs',
    resource: 'Logs',
    action: 'read',
  },
  {
    title: 'Ayarlar',
    icon: mdiCog,
    to: 'settings-view',
    resource: 'GeneralSettings',
    action: 'read',
  },
  {
    title: 'Integrations',
    href: `https://portalapi.tukas.com.tr/tr/integrations?token=${accessToken}`,
    icon: mdiFileDocumentOutline,
    resource: 'GeneralSettings',
    action: 'read',
  },
]
