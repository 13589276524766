/* eslint-disable no-unused-vars */

// eslint-disable-next-line import/prefer-default-export
export const convertArrayToObject = (array, key, value) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  array.reduce((acc, curr) => {
    acc[curr[key]] = curr[value]

    return acc
  }, {})
export const currency = new Intl.NumberFormat('tr-TR', {
  style: 'decimal',
  minimumFractionDigits: 2,
})
export async function postData(data = {}, signal) {
  const accessToken = localStorage.getItem('accessToken')
  let headers = {}
  if (accessToken) {
    headers = { accessToken }
  }

  try {
    const response = await fetch('https://portalapi.tukas.com.tr/tr/Api', {
      method: 'POST',
      headers,
      signal,
      body: new URLSearchParams(data), // body data type must match "Content-Type" header
    })

    const result = response.json().then(veri => {
      if (veri.error === 99) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')
        window.location.href = 'https://portal.tukas.com.tr/'
      }
      return veri
    })
    return result
  } catch (e) {}
}

export async function postDataPublic(data = {}, signal) {
  const headers = {}
  const response = await fetch('https://portalapi.tukas.com.tr/tr/Api', {
    method: 'POST',
    headers,
    signal,
    body: new URLSearchParams(data), // body data type must match "Content-Type" header
  })

  return response.json() // parses JSON response into native JavaScript objects
}

export async function postDataToken(data = {}, signal) {
  const { accessToken } = data
  let headers = {}
  if (accessToken) {
    headers = { accessToken }
  }
  const response = await fetch('https://portalapi.tukas.com.tr/tr/Api', {
    method: 'POST',
    headers,
    signal,
    body: new URLSearchParams(data), // body data type must match "Content-Type" header
  })

  return response.json() // parses JSON response into native JavaScript objects
}

export async function formData(data = {}, signal) {
  const accessToken = localStorage.getItem('accessToken')
  let headers = {}
  if (accessToken) {
    headers = { accessToken }
  }
  const response = await fetch('https://portalapi.tukas.com.tr/tr/Api', {
    method: 'POST',
    headers,
    signal,
    body: data, // body data type must match "Content-Type" header
  })

  return response.json() // parses JSON response into native JavaScript objects
}

export const convertDataTree = dataset => {
  const hashTable = Object.create(null)
  dataset.forEach(aData => (hashTable[aData.id] = { ...aData, children: [] }))
  const dataTree = []
  dataset.forEach(aData => {
    if (aData.parent_id) {
      hashTable[aData.parent_id].children.push(hashTable[aData.id])
    } else {
      dataTree.push(hashTable[aData.id])
    }
  })

  return dataTree
}

export const querySearch = (object, method) => {
  const newObject = {}
  newObject.method = method
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    if (!(value === null || value === '')) {
      // eslint-disable-next-line no-unused-expressions
      key === 'options' ? (newObject.options = JSON.stringify(value)) : (newObject[`search[${key}]`] = value)
    }
  }

  return newObject
}

export const fieldParams = (object, search, method, id = '') => {
  const newObject = {}
  newObject.method = method
  newObject.id = id
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    if (!(value === null || value === '')) {
      if (search.includes(key)) {
        newObject[`fieldx[tr][${key}]`] = value
      } else {
        newObject[`field[${key}]`] = value
      }
    }
  }

  return newObject
}

export const addDays = days => {
  const date = new Date()
  date.setDate(date.getDate() + days)

  return date
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export const formatDate = date =>
  `${[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-')}`
