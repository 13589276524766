<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <template #basket>
      <product-basket v-if="$store.state.app.basketDrawer"></product-basket>
    </template>
    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive, scrollY }">
      <!-- BU DİV <div

        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      > -->
      <div class="flex-grow-1">
        <!-- Left Content: Search -->

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center justify-space-between">
          <div v-if="$vuetify.breakpoint.mdAndDown" class="d-flex flex-grow-1 align-center">
            <router-link to="/">
              <v-img
                :src="isDark ? appLogoDark : appLogo"
                max-width="50px"
                alt="logo"
                contain
                eager
                class="app-logo ml-3"
              ></v-img>
            </router-link>

            <v-btn class="mx-1" icon @click="toggleVerticalNavMenuActive">
              <v-icon>
                {{ icons.mdiMenu }}
              </v-icon>
            </v-btn>

            <v-sheet v-if="$vuetify.breakpoint.mdOnly" color="" class="rounded-pill d-flex align-center">
              <v-list class="pa-0 rounded-pill" dense elevation="1" width="200">
                <v-list-item class="pr-3" @click="routerPush(userData.uid)">
                  <v-list-item-avatar>
                    <v-avatar class="primary--text">
                      <v-icon>
                        {{ icons.mdiAccountCircle }}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ userData.adsoyad }} </v-list-item-title>

                    <v-list-item-subtitle>{{ userData.uname }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <app-bar-theme-switcher class="ml-1"></app-bar-theme-switcher> -->
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" icon @click="logoutUser">
                <v-icon size="24">
                  {{ icons.mdiLogout }}
                </v-icon>
              </v-btn>
            </v-sheet>

            <v-sheet
              v-if="userData.role == 'PLSYR' && !$vuetify.breakpoint.xsOnly"
              class="flex-grow-1 ml-md-3 overflow-hidden rounded-pill"
              style="width: auto"
            >
              <customer-list
                v-if="userData.role === 'PLSYR' || userData.role === 'FIN'"
                :style-theme="true"
                class="pl-0 pl-lg-6"
              />
            </v-sheet>
          </div>

          <div v-else class="d-flex align-center flex-lg-grow-1">
            <v-sheet v-if="userData.role !== 'SUPER'" class="flex-grow-1 overflow-hidden rounded-pill">
              <customer-list
                v-if="userData.role === 'PLSYR' || userData.role === 'FIN'"
                :style-theme="true"
                class="pl-0 pl-lg-6"
              />

              <v-window v-if="userData.role === 'CUST'" show-arrows vertical>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                  </v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiChevronUp }}</v-icon>
                  </v-btn>
                </template>
                <v-window-item v-for="item in events" :key="`card-${item.id}`">
                  <v-list three-line class="pa-0 rounded-pill">
                    <v-list-item class="pl-2" style="height: 56px; min-height: 56px">
                      <v-list-item-avatar size="56" class="ma-0 mr-3">
                        <v-icon large>
                          {{ icons.mdiBullhorn }}
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0 mr-16 pr-6">
                        <v-list-item-subtitle>
                          {{ item.content }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-window-item>
              </v-window>
            </v-sheet>
            <v-sheet v-if="$vuetify.breakpoint.mdAndUp" color="" class="rounded-pill ml-3 d-flex align-center">
              <v-list class="pa-0 rounded-pill" dense elevation="1" width="200">
                <v-list-item class="pr-3" @click="routerPush(userData.uid)">
                  <v-list-item-avatar>
                    <v-avatar class="primary--text">
                      <v-icon>
                        {{ icons.mdiAccountCircle }}
                      </v-icon>
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ userData.adsoyad }} </v-list-item-title>

                    <v-list-item-subtitle>{{ userData.uname }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <app-bar-theme-switcher class="ml-1"></app-bar-theme-switcher> -->
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" class="mr-2" icon @click="logoutUser">
                <v-icon size="24">
                  {{ icons.mdiLogout }}
                </v-icon>
              </v-btn>
            </v-sheet>
          </div>

          <div v-if="userData.role !== 'SUPER'" class="d-flex">
            <!-- <v-btn
              v-if="( $vuetify.breakpoint.xlOnly) && backLinkStatus()"
              class="ml-3"
              fab
              color="tertiary"

              elevation="0"
              @click="$router.go(-1)"
            >
              <v-icon color="white">
                {{ icons.mdiArrowLeft }}
              </v-icon>
            </v-btn> -->

            <!-- <app-bar-search

            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive,scrollY)"
          ></app-bar-search> -->
            <v-sheet class="d-flex align-center ml-3 rounded-pill">
              <div v-if="userData.role != 'CUST'"><app-bar-basket></app-bar-basket></div>

              <!-- <v-btn v-if="!$vuetify.breakpoint.mdAndUp" class="mx-2" plain icon small @click="logoutUser">
                <v-icon>
                  {{ icons.mdiLogout }}
                </v-icon>
              </v-btn> 
            
            <delivery-date></delivery-date>-->
            </v-sheet>
          </div>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import store from '@/store'
import useAppConfig from '@core/@app-config/useAppConfig'
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { getVuetify, useRouter } from '@core/utils'
import {
  mdiAccountCircle,
  mdiArrowLeft,
  mdiBullhorn,
  mdiChevronDown,
  mdiChevronUp,
  mdiHeartOutline,
  mdiLogout,
  mdiMenu,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { postData } from '@utils'
import { getCurrentInstance, onMounted, ref, watch } from '@vue/composition-api'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

// App Bar Components
import AppBarBasket from '@/components/AppBarBasket.vue'
import ProductBasket from '@/components/basket/BasketList.vue'
import navMenuItems from '@/navigation/vertical'

// Search Data
import CustomerList from '@/components/CustomerList.vue'
import { initialAbility } from '@/plugins/acl/config'
import DeliveryDate from '@/views/order/order-to/order-components/DeliveryDate.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    ProductBasket,
    AppBarBasket,
    AppBarSearch,
    DeliveryDate,
    CustomerList,
    AppBarThemeSwitcher,
  },
  setup(props, context) {
    const $vuetify = getVuetify()
    const { isDark } = useAppConfig()
    const vm = getCurrentInstance()?.proxy
    const { router, route } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const events = ref([])

    onMounted(() => {
      console.log(navMenuItems)
      postData({
        method: 'getEvents',
      }).then(response => {
        events.value = response.response.result
      })
    })
    var standartx = 'Products'

    // watch(
    //   () => store.state.plasiyerCustomer,
    //   () => {
    //     navMenuItems = null
    //     if (store.state.secondState == true) {
    //       standartx = 'GeneralSettings'
    //       console.log(standartx)
    //       navMenuItems[4].resource = 'GeneralSettings'
    //       navMenuItems[7].resource = 'GeneralSettings'
    //       navMenuItems[8].resource = 'GeneralSettings'
    //       navMenuItems[14].resource = 'GeneralSettings'
    //     } else {
    //       navMenuItems[4].resource = 'KPanel'
    //       navMenuItems[7].resource = 'KPanel'
    //       navMenuItems[8].resource = 'KPanel'
    //       navMenuItems[14].resource = 'KPanel'
    //     }
    //     console.log(navMenuItems)
    //   },
    // )

    const routerPush = id => {
      router.push({ name: 'user-view', params: { id } })
    }
    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
      window.location.reload(true)
    }

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const backLinkStatus = () => {
      if (route.value.name === 'dashboard') {
        return false
      }

      return true
    }
    watch(() => route.value.name, backLinkStatus, { immediate: true })

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive, scrollY) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }
    const colorSelect = () => (store.state['app-order']?.basketItemCount > 0 ? null : null)

    return {
      events,
      routerPush,
      navMenuItems,
      handleShallShowFullSearchUpdate,
      logoutUser,
      userData,
      isDark,
      appLogo: themeConfig.app.logo,
      appLogoDark: themeConfig.app.logoDark,
      textLogoDark: themeConfig.app.textLogoDark,
      textLogo: themeConfig.app.textLogo,
      backLinkStatus,
      colorSelect,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      searchFilterFunc,
      appBarSearchData,
      icons: {
        mdiArrowLeft,
        mdiMenu,
        mdiHeartOutline,
        mdiLogout,
        mdiAccountCircle,
        mdiChevronDown,
        mdiChevronUp,
        mdiBullhorn,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
