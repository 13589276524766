// import '@/@fake-db/db'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { messages } from 'vee-validate/dist/locale/tr.json'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'
import JsonExcel from 'vue-json-excel'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VueTimeago from 'vue-timeago'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
Vue.component('downloadExcel', JsonExcel)
// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.use(VueCurrencyFilter, {
  symbol: 'TL',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'back',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})
Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'tr',
  locales: {
    tr: require('date-fns/locale/tr'),
  },
})
Vue.config.productionTip = false
Vue.use(VueTelInputVuetify, {
  vuetify,
})
const moment = require('moment')
require('moment/locale/tr')

Vue.use(require('vue-moment'), {
  moment,
})

const options = {
  confirmButtonColor: '#000000',
  confirmButtonText: 'Tamam',
  cancelButtonText: 'İptal',
  cancelButtonColor: '#ca2b34',
  background: '#0c6efc',
  color: '#fff',
  returnFocus: false,
  showCloseButton: true,
  iconColor: '#ffffff',
}

Vue.use(VueSweetalert2, options)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

extend('oneUpperCase', {
  validate: value => {
    const regLg = new RegExp('^(?=.*[A-Z])')

    return regLg.test(value)
  },
  message: '{_field_} en az bir büyük harf içermelidir.',
})

extend('oneLowerCase', {
  validate: value => {
    const regSm = new RegExp('^(?=.*[a-z])')

    return regSm.test(value)
  },
  message: '{_field_} en az bir küçük harf içermelidir.',
})

extend('oneNumber', {
  validate: value => {
    const regNum = new RegExp('(?=.*[0-9])')

    return regNum.test(value)
  },
  message: '{_field_} en az bir rakam içermelidir.',
})

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdiSvg',
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
