<template>
  <div :class="status ? 'd-md-flex justify-space-betwen' : 'd-flex'">
    <v-text-field
      v-model.number="number"
      v-number-only
      suffix="Ad"
      hide-details
      :disabled="!count"
      :filled="filled"
      :solo="solo"
      :background-color="BgColor"
      :color="ButtonColor"
      type="tel"
      :light="light"
      :dark="dark"
      dense
      :class="!count ? 'rounded' : $vuetify.breakpoint.smAndDown ? null : 'rounded-l rounded-r-0'"
      @blur="onBlurNumber(BirimRitim)"
      @focus="$event.target.select()"
    >
      <template v-slot:label>
        <div class="font-weight-medium text-body-1">Miktar</div>
      </template>
    </v-text-field>
    <v-sheet
      v-if="count"
      :color="BgColor"
      :class="
        $vuetify.breakpoint.smAndDown
          ? 'rounded-b d-flex justify-space-between align-center flex-row-reverse'
          : 'rounded-r'
      "
    >
      <v-btn class="rounded-0" text x-small :color="ButtonColor" @click="addNumber(BirimRitim)">
        <span v-if="number === 0">Ekle</span>
        <v-icon v-else small>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>

      <v-btn v-if="number > 0" class="rounded-0" text x-small :color="ButtonColor" @click="minusNumber(BirimRitim)">
        <v-icon small>
          {{ icons.mdiMinus }}
        </v-icon>
      </v-btn>
    </v-sheet>
  </div>
</template>

<script>
import store from '@/store'
import { mdiMinus, mdiPlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {
  directives: {
    'number-only': {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      },
    },
    'letter-only': {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }

          return true
        }
        el.addEventListener('keypress', checkValue)
      },
    },
  },
  props: {
    paymCond: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'secondary',
    },
    uid: {
      default: null,
    },
    plantId: {
      type: String,
      default: '01',
    },

    buttonColor: {
      type: String,
      default: 'white',
    },
    birimRitim: {
      type: Number,
      default: 50,
    },
    birim: {
      type: String,
      default: 'Adet',
    },
    miktar: {
      type: String,
      default: '1',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'refresh'],
  setup(props, { emit }) {
    const number = ref(50)
    const Birim = ref(props.birim)
    const BirimRitim = ref(props.birimRitim)
    const BgColor = ref(props.bgColor)
    const ButtonColor = ref(props.buttonColor)
    const refresh = () => {
      emit('refreshBasket')
    }
    const addBasket = qty => {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid: props.uid,
          qty,
          paymCond: props.paymCond,
          plant: props.plantId,
        })
        .then(response => {
          if (response.error) {
          } else {
            refresh()
          }
        })
    }

    const addNumber = val => {
      number.value += Number(val) - Number(number.value % val)
      if (props.status) {
        addBasket(number.value)
      }
      console.log(number.value)
    }

    number.value = Number(props.miktar)

    const select = () => {
      emit('selected', number)
    }
    const changeNumber = () => {
      number.value = 1
    }
    watch(number, () => {
      select()
    })

    const onBlurNumber = val => {
      addBasket(number.value)
    }
    // eslint-disable-next-line no-return-assign
    const minusNumber = val => {
      number.value != 1
        ? (number.value -= Number(number.value % val) === 0 ? Number(val) : Number(number.value % val))
        : 1
      if (number.value === 0) {
        number.value = 1
      }
      addBasket(number.value)
    }

    return {
      Birim,
      number,
      select,
      BgColor,
      ButtonColor,
      BirimRitim,
      addNumber,
      minusNumber,
      onBlurNumber,
      changeNumber,
      icons: {
        mdiPlus,
        mdiMinus,
      },
    }
  },
}
</script>

<style lang="scss">
.completed {
  label,
  .v-text-field__suffix,
  input {
    color: #3b355a !important;
    text-shadow: none !important;
  }
}
</style>
